window.cookieconsent.initialise({
  container: document.getElementById("cookieconsent"),
  palette:{
    popup: { background: "#1aa3ff" },
    button: { background: "#e0e0e0" },
  },
  revokable: true,
  onStatusChange: function(status) {
    console.log(this.hasConsented() ?
     'enable cookies' : 'disable cookies');
  },
  "theme": "classic",
  "domain": "https://rgc.completecomtechsolutions.co.uk",
  "secure": true,
  "content":{
    "header": 'Cookies used on the website!',
    "message": 'This website uses one temporary session cookie that will automatically be removed when you close your browser. This is an essential cookie and does nothing to identify you. We value your privacy!',
    "position": 'bottom',
    "dismiss": 'Got it!',
    "allow": 'Allow cookies',
    "deny": 'Decline',
    "link": 'Learn more',
    "href": 'https://www.cookiesandyou.com',
    "close": '&#x274c;',
    "policy": 'Cookie Policy',
    "target": '_blank',
  }
});
